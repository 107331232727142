<template>
    <v-container>
        <v-row>
            <v-col cols="3">
                <v-card elevation="0" rounded="lg" class="py-3 px-5">
                    <span
                        class="grey--text lighten-3 body-2 font-weight-medium"
                    >
                        Asignacion
                    </span>
                    <v-select
                        outlined
                        :items="assignationStateOptions"
                        v-model="currentAssignationState"
                        item-value="id"
                        item-text="name"
                        color="primary"
                        prepend-inner-icon="mdi-clipboard-text-search"
                        hide-details
                        small-chips
                        hidden
                        no-data-text="There is no types"
                        placeholder="Select a type"
                        dense
                    >
                        <template v-slot:selection="{ item }">
                            <div color="deep-purple lighten-5" class="w-100">
                                <v-chip small label color="primary white--text" class="white--text">{{
                                    item.name
                                }}</v-chip>
                            </div>
                        </template>

                        <template v-slot:item="{ item, attrs, on }">
                            <v-list-item v-bind="attrs" v-on="on">
                                <v-list-item-action>
                                    <v-radio-group v-model="currentAssignationState">
                                        <v-radio color="primary" :value="item.id"></v-radio>
                                    </v-radio-group>
                                </v-list-item-action>
                                <v-list-item-content>
                                    <v-list-item-title :id="attrs['aria-labelledby']">
                                        <span>{{ item.name }}</span>
                                    </v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                        </template>
                    </v-select>
                </v-card>
            </v-col>

            <v-spacer></v-spacer>
        </v-row>

        <v-card elevation="0" rounded="xl" class="my-6 pa-6">
            <AssignedBooksTable 
                :headers="assignedBooksHeaders"
                :items="getCurrentAssignedItems"
                :hideDefaultFooter="true"
                :loading="loadingAssigment"
                :isOldAssignmnents="isOldAssignments"
                :itemsPerPage="itemsPerPage"
            />
            <v-divider class="ma-2"></v-divider>
            <v-row v-if="!loadingAssigment && getCurrentAssignedItems.length > itemsPerPage" align-content="center" justify="center">
                <v-btn text color="primary" :disabled="canLoadMoreAssignments" @click="loadMoreAssignments">
                    <span class="body-1 font-weight-medium text-capitalize">
                        {{$t('assignments.actions.loadMoreAssignments')}}
                    </span>
                </v-btn>
            </v-row>
        </v-card>

    </v-container>

</template>

<script>
import moment from 'moment'
import { mapState, mapActions } from 'vuex'
import courseBook from '../../services/courseBook'
import BookList from '@/components/library/BookList.vue'
import BookAssingmentItem from './core/BookAssingmentItem'
import BeeTabs from '../core/BeeTabs'
import AssignedBooksTable from './shared/tables/assignedBooks/index.vue'

const INITIAL_ITEMS_PER_PAGE = 6

export default {
    components: {
        BeeTabs,
        BookList,
        BookAssingmentItem,
        AssignedBooksTable,
    },
    data() {
        return {
            tab: 0,
            currentTab: 0,
            loadingData: false,
            currentAssignationState: 0,
            currentAssignationType: 0,
            itemsPerPage: INITIAL_ITEMS_PER_PAGE,
        }
    },
    async mounted() {
        this.loadAssigment()
        const list = await courseBook
            .getListByTeacherId(this.userId)
            .catch((err) => console.error(err))
        console.debug(list)
        this.setAssignedBookList(list.content)
        this.loadAssigment()
    },
    computed: {
        ...mapState({
            assignedBooksList: (state) => state.library.assignedBooksList,
            userId: (state) => state.userData.user.id,
            loadingAssigment: (state) => state.loadingAssigment,
        }),
        assignedBooksHeaders() {
            return [
                {
                    text: this.$t('assignments.titles.assignmentTableTitles.bookTitle'),
                    align: 'start',
                    sortable: false,
                    value: 'book',
                },
                {
                    text: this.$t('assignments.titles.assignmentTableTitles.assignTo'),
                    align: 'center',
                    sortable: false,
                    value: 'assignedTo',
                },
                {
                    text: this.$t('assignments.titles.assignmentTableTitles.startDate'),
                    align: 'center',
                    sortable: false,
                    value: 'startDate',
                },
                {
                    text: this.$t('assignments.titles.assignmentTableTitles.endDate'),
                    align: 'center',
                    sortable: false,
                    value: 'endDate',
                },
                {
                    text: this.$t('assignments.titles.assignmentTableTitles.actions'),
                    align: 'center',
                    sortable: false,
                    value: 'actions',
                },
            ]
        },
        assignationStateOptions() {
            return [
                {
                    id: 0,
                    name: this.$t('assignments.inputs.assignationType.actives')
                },
                // {
                //     id: 1,
                //     name: this.$t('assignments.inputs.assignationType.futureAssignations')

                // },
                {
                    id: 2,
                    name: this.$t('assignments.inputs.assignationType.inactives')
                }
            ]
        },
        assignationTypeOptions() {
            return [
                {
                    id: 0,
                    name: 'Cursos'
                },
                {
                    id: 1,
                    name: 'Libros'
                }
            ]
        },
        items() {
            return [this.$t('library.active_books'), this.$t('library.inactive_books')]
        },
        activeAssigmentsItem() {
            const CurrentDate = moment();

            if (this.assignedBooksList) {
                return this.assignedBooksList.filter(
                    (item) => CurrentDate.isBetween(item.startingDate, moment(item.evaluationDate).endOf('day'), undefined, []) === true || CurrentDate.isBefore(moment(item.evaluationDate).endOf('day')) === true
                )
            }

            return []
        },
        inactiveAssigmentsItem() {
            const CurrentDate = moment().subtract(1, 'day');

            if (this.assignedBooksList) {
                return this.assignedBooksList.filter(
                    (item) => moment(item.evaluationDate).isSameOrBefore(CurrentDate) === true
                )
            }

            return []
        },
        isOldAssignments() {
            if(this.currentAssignationState === 2) {
                return true
            }

            return false
        },
        getCurrentAssignedItems() {
            if(this.currentAssignationState === 0) {
                return this.activeAssigmentsItem
            }

            if(this.currentAssignationState === 2) {
                return this.inactiveAssigmentsItem
            }

            return []
        },
        canLoadMoreAssignments() {
            return this.getCurrentAssignedItems.length < this.itemsPerPage
        }
    },
    methods: {
        ...mapActions('library', ['setAssignedBookList']),
        ...mapActions(['loadAssigment']),
        changeTab(tab) {
            this.currentTab = tab
        },
        showEditBtn(book) {
            const { evaluationDate } = book
            const lastMonth = this.$moment().subtract(1, 'months')

            return this.$moment().diff(evaluationDate) < 0
                || this.$moment(evaluationDate).isBetween(lastMonth, evaluationDate, undefined, '(]')
        },
        loadMoreAssignments() {
            this.itemsPerPage += INITIAL_ITEMS_PER_PAGE
        }
    },
    watch: {
        currentAssignationType() {
            this.itemsPerPage = INITIAL_ITEMS_PER_PAGE
        }
    },
}
</script>
<style lang="scss">

.no-uppercase {
    text-transform: none;
}

.tab-items {
    border-bottom: 6px solid #f1f1f1;
    margin-left: 10px;
    margin-right: 10px;
    padding-left: 0;
    padding-right: 40px;
}
</style>
