<template>
    <v-container>
        <v-data-table
            :headers="headers"
            :items="items"
            :items-per-page="itemsPerPage"
            :hide-default-footer="hideDefaultFooter"
            :sort-by="sortByHeaders"
            :sort-desc="sortByDesc"
            header-props="purple-light--color"
            class="assigned-books__table elevation-0"
            :loading="loading"
        >
            <template v-slot:item.book="{ item }">
                <v-row class="assigned-books__table--book-progress-column">
                    <v-col cols="3">
                        <v-img
                            width="420"
                            :src="`https://assets.beereaders.com/lbr/image/${item.book.resourceDir}/cover_small.jpg?v=${new Date().getTime()}`"
                            class="rounded-lg"
                        ></v-img>
                    </v-col>
                    <v-col>
                        <h2 class="subtitle-1 font-weight-bold">
                            {{ item.book.title }}
                        </h2>
                        <strong class="body-2 grey--text lighten-5 font-weight-medium">
                            {{ getBookAuthor(item.book) || $t('assignments.utils.books.noAuthor') }}
                        </strong>
                        <div
                            class="d-flex flex-row flex-nowrap align-items-center justify-content-start my-2"
                        >
                            <span class="body-2 font-weight-bold">{{ item.progressPercentage }}%</span>
                            <v-progress-linear
                                class="my-1 ml-3 assigned-books__table--book-progress"
                                :value="item.progressPercentage"
                                color="primary"
                                rounded
                                height="9px"
                            ></v-progress-linear>
                        </div>
                    </v-col>
                </v-row>
            </template>

            <!-- Course Name -->
            <template v-slot:item.assignedTo="{ item }">
                <v-col class="d-flex flex-column align-center justify-content-center">
                    <v-row>
                        <v-chip label color="deep-purple lighten-5" class="px-3 mb-2" v-if="item.group && item.group.name">
                            <span class="text-capitalize primary--text">{{
                                item.group.name
                            }}</span>
                        </v-chip>
                    </v-row>
                    <v-row>
                        <v-chip label color="deep-purple lighten-5" class="px-3">
                            <v-icon small color="primary" class="mr-3">mdi-folder</v-icon>
                            <span class="text-capitalize primary--text">{{
                                `${$t('course')} ${item.course.name}`
                            }}</span>
                        </v-chip>
                    </v-row>
                    <v-row class="px-3">
                        <v-chip label color="deep-purple lighten-5" class="px-3 my-2" v-if="item.group">
                            <v-icon small color="primary" class="mr-3">mdi-account</v-icon>
                            <span class="text-capitalize primary--text">{{
                            `${item.group.users.length} ${item.group.users.length > 1 ? $t('assignments.utils.students.multiple') : $t('assignments.utils.students.single')}`
                            }}</span>
                        </v-chip>
                    </v-row>

                    <!-- Group type -->
                    <!-- COMMENTED UNTIL WE FIX STYLES PROBLEM FOR ENVIRONMENT -->
                    <!-- <v-row v-if="item.group">
                        <v-row align-content="center" justify="center" v-if="item.group.groupType !== 'TIER'" class="no-margin no-padding">
                            <v-chip label color="deep-purple lighten-5" class="px-3" small>
                                <span class="primary--text text-capitalize" >
                                    {{ item.group && item.group.groupType && $t(`assignments.utils.groupType.${item.group.groupType}`) }}
                                </span>
                            </v-chip>
                        </v-row>
                        <v-row v-if="item.group && item.group.groupType === 'TIER' && item.group.tierName" align-content="center" justify="center">
                            <v-chip
                                :color="defineChipTierColor(item.group.tierName)"
                                text-color="white"
                                class="font-weight-medium"
                                small
                                label
                                data-cy="table-tier-chip"
                            >
                                {{ $t(`assignments.utils.tiers.${item.group.tierName}`) }}
                            </v-chip>
                        </v-row>
                    </v-row> -->
                </v-col>
            </template>

            <!-- Start Date -->
            <template v-slot:item.startDate="{ item }">
                <v-chip label color="deep-purple lighten-5" class="px-2 d-block my-1">
                    <v-icon small color="primary" class="mr-2">mdi-calendar</v-icon>
                    <span class="text-capitalize primary--text">{{
                        $moment(item.startingDate).format('DD MMM YYYY')
                    }}</span>
                </v-chip>
                <span class="body-2 font-weight-light grey--text lighten-5 my-2">
                    {{ getCreatedDiffDays($moment(new Date()).diff(item.startingDate, 'days')) }}
                </span>
            </template>

            <!-- Evaluation Date -->
            <template v-slot:item.endDate="{ item }">
                <v-chip label color="deep-purple lighten-5" class="px-2 d-block my-1">
                    <v-icon small color="primary" class="mr-2">mdi-calendar</v-icon>
                    <span class="text-capitalize primary--text">{{
                        $moment(item.evaluationDate).format('DD MMM YYYY')
                    }}</span>
                </v-chip>
                <span class="body-2 font-weight-light grey--text lighten-5 my-2">
                    {{ getEvaluationDiffDays($moment(new Date()).diff(item.evaluationDate, 'days')) }}
                </span>
            </template>

            <!-- Actions -->
            <template v-slot:item.actions="{ item }">
                <v-row class="flex-nowrap" align-content="center" justify="center">
                    <!-- Stats -->
                    <v-btn
                        data-cy="stats-assignment-btn"

                        icon
                        elevation="0"
                        class="primary ma-1 pa-3"
                        small
                        @click="goToStats(item)"
                    >
                        <v-icon small class="pa-3" color="white">mdi-chart-timeline-variant-shimmer</v-icon>
                    </v-btn>

                    <!-- Edit -->
                    <v-btn
                        data-cy="edit-assignment-btn"

                        icon
                        elevation="0"
                        class="deep-purple lighten-5 ma-1 pa-3"
                        small
                        @click="goToModify(item)"
                    >
                        <v-icon small class="pa-3" color="primary">mdi-pencil</v-icon>
                    </v-btn>

                    <!-- Delete -->
                    <v-btn

                        data-cy="delete-assignment-btn"
                        v-if="!isOldAssignmnents"
                        icon
                        elevation="0"
                        class="red lighten-5 ma-1 pa-3"
                        small
                        @click="deleteBookAssigment(item)"
                    >
                        <v-icon small class="pa-3" color="red">mdi-trash-can-outline</v-icon>
                    </v-btn>
                </v-row>
            </template>
        </v-data-table>

        <!-- Snackbars -->
        <v-snackbar v-model="snackbarError" :timeout="timeout" color="error">
                {{ $t('library.assigment_deleted_error') }}

                <template v-slot:action="{ attrs }">
                    <v-btn color="error" icon text v-bind="attrs" @click="snackbarError = false">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </template>
            </v-snackbar>
            <v-snackbar  v-model="snackbarSuccess" :timeout="timeout" color="success" outlined >
                {{ $t('library.assigment_deleted_success') }}

                <template v-slot:action="{ attrs }">
                    <v-btn color="success" icon text v-bind="attrs" @click="snackbarSuccess = false">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </template>
            </v-snackbar>
    </v-container>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import courseBookService from '../../../../../services/courseBook'

export default {
    name: 'AssignedBooksTable',
    props: {
        headers: { type: Array, default: () => [] },
        sortByHeaders: { type: Array, default: [] },
        sortByDesc: { type: Array, default: [] },
        items: { type: Array, default: () => [] },
        itemsPerPage: { type: [String, Number], default: -1 },
        hideDefaultFooter: { type: Boolean, default: true },
        loading: { type: Boolean, default: false, required: true },
        isOldAssignmnents: { type: Boolean, default: false, required: false },
    },
    data() {
        return {
            snackbarSuccess: false,
            snackbarError: false,
            timeout: 2000,
        }
    },
    computed: {
        ...mapState({
            userId: (state) => state.userData.user.id,
        }),
    },
    methods: {
        ...mapActions('library', ['setAssignedBookList']),
        ...mapActions(['loadAssigment']),
        getCreatedDiffDays(diff) {
            const absoluteNumber = Math.abs(diff)
            if (diff === 0) {
                return `${this.$t('assignments.utils.diffDays.startsInLessThan')} 24h`
            }

            if (diff < 0) {
                return `${this.$t('assignments.utils.diffDays.startsIn')} ${absoluteNumber} ${this.$t('assignments.utils.diffDays.days')}`
            }

            return `${this.$t('assignments.utils.diffDays.createdAgo')} ${absoluteNumber} ${absoluteNumber > 1 ? `${this.$t('assignments.utils.diffDays.daysAgo')}` : `${this.$t('assignments.utils.diffDays.dayAgo')}`}`
        },
        getEvaluationDiffDays(diff) {
            const absoluteNumber = Math.abs(diff)
            if (diff === 0) {
                return `${this.$t('assignments.utils.diffDays.remainLess')} 24h`
            }

            if (diff < 0) {
                return `${this.$t('assignments.utils.diffDays.remain')} ${absoluteNumber} ${absoluteNumber > 1 ? `${this.$t('assignments.utils.diffDays.days')}` : `${this.$t('assignments.utils.diffDays.day')}`}`
            }

            if (diff > 0) {
                return `${this.$t('assignments.utils.diffDays.finished')} ${absoluteNumber} ${absoluteNumber > 1 ? `${this.$t('assignments.utils.diffDays.daysAgo')}` : `${this.$t('assignments.utils.diffDays.dayAgo')}`}`
            }
        },
        goToStats(book) {
            this.$router.push(`stats/${book.id}`)
        },
        goToModify(bookData) {
            const {
 id, book, course, startingDate, evaluationDate
} = bookData
            const isPastBook = this.showRemoveBtn ? '' : '?pastBook=true'
            this.$router.push(
                `/teacher/library/book/${book.id}/${course.id}/${id}/${startingDate}/${evaluationDate}${isPastBook}`
            )
        },
        deleteBookAssigment(book) {
            const vm = this
            this.$modal.confirm(
                this.$t('library.confirm_delete'),
                this.$t('library.confirm_delete_msg'),
                async () => {
                    vm.loadAssigment()
                    const response = await courseBookService
                        .deleteCourseBook(book.id)
                        .catch((e) => console.error(e))
                    if (response.content === true) vm.snackbarSuccess = true
                    else vm.snackbarError = true

                    const list = await courseBookService
                        .getListByTeacherId(vm.userId)
                        .catch((err) => console.error(err))
                    this.setAssignedBookList(list.content)
                    vm.loadAssigment()
                    courseBookService.getBookListByCourseAndDate({ courseId: book.course.id, date: this.$moment().format('YYYY-MM-DD') }, true)
                }
            )
        },
        getBookAuthor(book) {
            if (!book.bookAuthorDTOList.length) {
                return null
            }
            return book.bookAuthorDTOList[0].name
        },
        defineChipTierColor(tier) {
            switch (tier) {
                case 'TIER_GROUP_1':
                    return 'green darken-2'
                case 'TIER_GROUP_2':
                    return 'amber accent-3'
                case 'TIER_GROUP_3':
                    return 'red darken-2'
                default:
                    return null
            }
            },
    }
}
</script>

<style lang="scss">
@import '../../../../../assets/style/colors.scss';

.headers-table {
    background: $purple-primary !important;
}

.assigned-books__table .v-data-table-header {
    background-color: $purple-primary !important;
}
.assigned-books__table .v-data-table-header .sortable.active {
    background-color: #d7d2ef;
}
.v-data-table__wrapper > table > thead > tr:last-child > th {
    border-bottom: none !important;
}

.assigned-books__table td {
    padding-top: 5px !important;
}

.assigned-books__table--book-progress-column {
    max-width: 360px;
}

.assigned-books__table--book-progress {
    max-width: 90px;
}
</style>
